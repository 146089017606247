<template>
  <v-card elevation="0">
    <v-card-title>
      {{'module.otp'| t}}
    </v-card-title>

    <v-card-text>
      <v-otp-input
        @finish="loginViaOtp"
        dense
        class="mb-4"
        :autofocus="!$route.query.code"
        type="number"
        v-model="code"
      ></v-otp-input>

      <v-btn
        block
        color="primary"
        @click="loginViaOtp"
        class="mb-4"
        :loading="loading"
        :disabled="disabled"
      >
        {{'action.apply'| t}}
      </v-btn>

      <router-link :to="{ name: 'login' }" class="text-decoration-none">
        {{'action.login'| t}}
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('action.forgot-password'),
    }
  },
  data: () => ({
    target: null,
    code: null,
    loading: false,
  }),
  beforeDestroy() {
    this.target = null
    this.code = null
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const query = this.$route.query
      if(query.target) this.target = query.target
      if(query.code) this.code = query.code
    },
    async loginViaOtp() {
      this.loading = true
      try {
        const result = await this.$api.collection.siteAdminAccountApi.loginViaOtp({
          feature: this.$otpConstants.FEATURE_SITE_ADMIN_RESET_PASSWORD,
          target: this.target,
          code: this.code,
        })
        this.$tokenStore.set(result.token)
        this.$helper.account.login()
        await this.$helper.delay(1)
        await this.$router.replace({
          name: 'reset-password'
        })
      } catch (error){
        console.warn(error)
        let message = this.$t('error.unexpect')
        if(error.status == 403) message = this.$t('error.login_via_otp.403')
        if(error.status == 409) message = this.$t('error.login_via_otp.409')
        this.$snotify.error(message, this.$t('error.send_mail.failure'))
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    disabled() {
      if(!this.$helper.validator.required(this.code)) return true
      if(!this.$helper.validator.required(this.target)) return true
      if(!this.$helper.validator.email(this.target)) return true
      return false
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
